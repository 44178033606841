<template>
  <b-card title="GVP Yapılandırması">
    <b-form-group
      label="Terminal ID (TID)"
      label-for="gvp_terminal_id"
    >
      <b-form-input
        id="gvp_terminal_id"
        v-model="posConfig.config.gvp_terminal_id"
        placeholder="Terminal ID (TID)"
      />
    </b-form-group>
    <b-form-group
      label="Merchant ID (MID)"
      label-for="gvp_merchant_id"
    >
      <b-form-input
        id="gvp_merchant_id"
        v-model="posConfig.config.gvp_merchant_id"
        placeholder="Merchant ID (MID)"
      />
    </b-form-group>
    <b-form-group
      label="Müşteri Adı (Kullanıcı Adı, User Name)"
      label-for="gvp_user_name"
    >
      <b-form-input
        id="gvp_user_name"
        v-model="posConfig.config.gvp_user_name"
        placeholder="Müşteri Adı (Kullanıcı Adı, User Name)"
      />
    </b-form-group>
    <b-form-group
      label="Provizyon Şifresi (Provaut Password)"
      label-for="gvp_provaut_password"
    >
      <b-form-input
        id="gvp_provaut_password"
        v-model="posConfig.config.gvp_provaut_password"
        placeholder="Provizyon Şifresi (Provaut Password)"
      />
    </b-form-group>
    <b-form-group
      label="3D Şifresi (3D Store Key, Merchant Key)"
      label-for="gvp_3d_store_key"
    >
      <b-form-input
        id="gvp_3d_store_key"
        v-model="posConfig.config.gvp_3d_store_key"
        placeholder="3D Şifresi (3D Store Key, Merchant Key)"
      />
    </b-form-group>
    <b-form-group
      label="Doğrudan Ödeme Bağlantısı (Api-Classic URL)"
      label-for="gvp_api_url"
    >
      <b-form-input
        id="gvp_api_url"
        v-model="posConfig.config.gvp_api_url"
        placeholder="Doğrudan Ödeme Bağlantısı (Api-Classic URL)"
      />
    </b-form-group>
    <b-form-group
      label="3D Bağlantısı (3D, 3DGate URL)"
      label-for="gvp_3d_url"
    >
      <b-form-input
        id="gvp_3d_url"
        v-model="posConfig.config.gvp_3d_url"
        placeholder="3D Bağlantısı (3D, 3DGate URL)"
      />
    </b-form-group>
  </b-card>
</template>
<script>
import { BCard, BFormGroup, BFormInput } from 'bootstrap-vue'

export default {
  name: 'Gvp',
  components: {
    BCard,
    BFormGroup,
    BFormInput,
  },
  computed: {
    posConfig() {
      return this.$store.getters['posConfigs/getPos_config']
    },
  },
}
</script>
<style scoped></style>
