<template>
  <b-card title="Payfor Yapılandırması">
    <b-form-group
      label="Üye İşyeri Numarası (Merchant ID,MID)"
      label-for="payfor_merchant_id"
    >
      <b-form-input
        id="payfor_merchant_id"
        v-model="posConfig.config.payfor_merchant_id"
        placeholder="Üye İşyeri Numarası (Merchant ID,MID)"
      />
    </b-form-group>
    <b-form-group
      label="Üye İşyeri 3D Şifresi (3D Password)"
      label-for="payfor_merchant_pass"
    >
      <b-form-input
        id="payfor_merchant_pass"
        v-model="posConfig.config.payfor_merchant_pass"
        placeholder="Üye İşyeri 3D Şifresi (3D Password)"
      />
    </b-form-group>
    <b-form-group
      label="Kullanıcı Kodu (Api User)"
      label-for="payfor_usercode"
    >
      <b-form-input
        id="payfor_usercode"
        v-model="posConfig.config.payfor_usercode"
        placeholder="Kullanıcı Kodu (Api User)"
      />
    </b-form-group>
    <b-form-group
      label="Kullanıcı Şifre (Api User Pass)"
      label-for="payfor_userpass"
    >
      <b-form-input
        id="payfor_userpass"
        v-model="posConfig.config.payfor_userpass"
        placeholder="Kullanıcı Şifre (Api User Pass)"
      />
    </b-form-group>
    <b-form-group
      label="Doğrudan Ödeme Bağlantısı (Api-Classic URL)"
      label-for="payfor_api_url"
    >
      <b-form-input
        id="payfor_api_url"
        v-model="posConfig.config.payfor_api_url"
        placeholder="Doğrudan Ödeme Bağlantısı (Api-Classic URL)"
      />
    </b-form-group>
    <b-form-group
      label="3D Bağlantısı (3D, 3DGate URL)"
      label-for="payfor_3d_url"
    >
      <b-form-input
        id="posnet_3d_url"
        v-model="posConfig.config.payfor_3d_url"
        placeholder="3D Bağlantısı (3D, 3DGate URL)"
      />
    </b-form-group>
  </b-card>
</template>
<script>
import { BCard, BFormGroup, BFormInput } from 'bootstrap-vue'

export default {
  name: 'Payfor',
  components: {
    BCard,
    BFormGroup,
    BFormInput,
  },
  computed: {
    posConfig() {
      return this.$store.getters['posConfigs/getPos_config']
    },
  },
}
</script>
<style scoped></style>
