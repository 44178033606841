<template>
  <b-card title="Taksit Seçenekleri">
    <b-row
      v-for="(item,key) in posConfig.installments"
      :key="key"
    >
      <b-col>
        <b-form-group label="Taksit">
          <b-input-group>
            <b-input-group-prepend>
              <b-button
                :disabled="key === 0"
                variant="outline-danger"
                @click="removeInstallment(key)"
              >
                <FeatherIcon icon="XCircleIcon" />
              </b-button>
            </b-input-group-prepend>
            <b-form-input
              v-model="item.text"
              :disabled="key === 0"
              placeholder="Taksit Sayısı"
            />
          </b-input-group>
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group label="Vade Ay">
          <b-input-group>
            <b-input-group-prepend is-text>
              Ay
            </b-input-group-prepend>
            <cleave
              v-model="item.installment"
              class="form-control"
              :disabled="key === 0"
              :raw="true"
              :options="options.number"
              placeholder="Taksit Sayısı"
            />
          </b-input-group>
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group label="Vade Farkı">
          <b-input-group>
            <b-input-group-prepend is-text>
              %
            </b-input-group-prepend>
            <cleave
              v-model="item.rate"
              class="form-control"
              :disabled="key === 0"
              :raw="true"
              :options="options.number"
              placeholder="Vade Farkı"
            />
          </b-input-group>
        </b-form-group>
      </b-col>
    </b-row>
    <b-button
      variant="primary"
      class="mt-1"
      @click="addInstallment"
    >
      <FeatherIcon icon="PlusIcon" />
      Taksit Ekle
    </b-button>
  </b-card>
</template>
<script>
import {
  BCard, BFormGroup, BRow, BCol, BButton, BInputGroup, BInputGroupPrepend, BFormInput,
} from 'bootstrap-vue'
import Cleave from 'vue-cleave-component'

export default {
  name: 'Installments',
  components: {
    BCard,
    BFormGroup,
    BRow,
    BCol,
    BButton,
    BInputGroup,
    BInputGroupPrepend,
    BFormInput,
    Cleave,
  },
  data() {
    return {
      options: {
        number: {
          numeral: true,
          numeralDecimalMark: ',',
        },
      },
    }
  },
  computed: {
    posConfig() {
      return this.$store.getters['posConfigs/getPos_config']
    },
  },
  methods: {
    addInstallment() {
      const { installments } = this.posConfig
      installments.push({
        text: 'Taksit',
        installment: 0,
        rate: 0,
      })
      this.posConfig.installments = installments
    },
    removeInstallment(index) {
      const { installments } = this.posConfig
      installments.splice(index, 1)
      this.posConfig.installments = installments
    },
  },
}
</script>
