<template>
  <b-card title="Nestpay Yapılandırması">
    <b-form-group
      label="Müşteri No (Client ID)"
      label-for="nestpay_client_id"
    >
      <b-form-input
        id="nestpay_client_id"
        v-model="posConfig.config.nestpay_client_id"
        placeholder="Müşteri No (Client ID)"
      />
    </b-form-group>
    <b-form-group
      label="Müşteri Adı (Client Api Name)"
      label-for="nestpay_client_api_name"
    >
      <b-form-input
        id="nestpay_client_api_name"
        v-model="posConfig.config.nestpay_client_api_name"
        placeholder="Müşteri Adı (Client Api Name)"
      />
    </b-form-group>
    <b-form-group
      label="Şifre (Classic-Api Password)"
      label-for="nestpay_classic_api_password"
    >
      <b-form-input
        id="nestpay_classic_api_password"
        v-model="posConfig.config.nestpay_classic_api_password"
        placeholder="Şifre (Classic-Api Password)"
      />
    </b-form-group>
    <b-form-group
      label="3D Şifresi (3D Store Key,Merchant Key)"
      label-for="nestpay_3d_store_key"
    >
      <b-form-input
        id="nestpay_3d_store_key"
        v-model="posConfig.config.nestpay_3d_store_key"
        placeholder="3D Şifresi (3D Store Key,Merchant Key)"
      />
    </b-form-group>
    <b-form-group
      label="Doğrudan Ödeme Bağlantısı (Api-Classic URL)"
      label-for="nestpay_api_url"
    >
      <b-form-input
        id="nestpay_api_url"
        v-model="posConfig.config.nestpay_api_url"
        placeholder="Doğrudan Ödeme Bağlantısı (Api-Classic URL)"
      />
    </b-form-group>
    <b-form-group
      label="3D Bağlantısı (3D, 3DGate URL)"
      label-for="nestpay_3d_url"
    >
      <b-form-input
        id="nestpay_3d_url"
        v-model="posConfig.config.nestpay_3d_url"
        placeholder="3D Bağlantısı (3D, 3DGate URL)"
      />
    </b-form-group>
  </b-card>
</template>
<script>
import { BCard, BFormGroup, BFormInput } from 'bootstrap-vue'

export default {
  name: 'Nestpay',
  components: {
    BCard,
    BFormGroup,
    BFormInput,
  },
  computed: {
    posConfig() {
      return this.$store.getters['posConfigs/getPos_config']
    },
  },
}
</script>
<style scoped></style>
