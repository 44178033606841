<template>
  <b-card title="Posnet Yapılandırması">
    <b-form-group
      label="Posnet ID (Client ID, PID)"
      label-for="posnet_client_id"
    >
      <b-form-input
        id="posnet_client_id"
        v-model="posConfig.config.posnet_client_id"
        placeholder="Posnet ID (Client ID, PID)"
      />
    </b-form-group>
    <b-form-group
      label="Terminal ID (TID)"
      label-for="posnet_terminal_id"
    >
      <b-form-input
        id="posnet_terminal_id"
        v-model="posConfig.config.posnet_terminal_id"
        placeholder="Terminal ID (TID)"
      />
    </b-form-group>
    <b-form-group
      label="Merchant ID (MID)"
      label-for="posnet_merchant_id"
    >
      <b-form-input
        id="posnet_merchant_id"
        v-model="posConfig.config.posnet_merchant_id"
        placeholder="Merchant ID (MID)"
      />
    </b-form-group>
    <b-form-group
      label="Şifreleme Anahtarı (EncKey)"
      label-for="posnet_encKey"
    >
      <b-form-input
        id="posnet_encKey"
        v-model="posConfig.config.posnet_encKey"
        placeholder="Şifreleme Anahtarı (EncKey)"
      />
    </b-form-group>
    <b-form-group
      label="Doğrudan Ödeme Bağlantısı (Api-Classic URL)"
      label-for="posnet_api_url"
    >
      <b-form-input
        id="posnet_api_url"
        v-model="posConfig.config.posnet_api_url"
        placeholder="Doğrudan Ödeme Bağlantısı (Api-Classic URL)"
      />
    </b-form-group>
    <b-form-group
      label="3D Bağlantısı (3D, 3DGate URL)"
      label-for="posnet_3d_url"
    >
      <b-form-input
        id="posnet_3d_url"
        v-model="posConfig.config.posnet_3d_url"
        placeholder="3D Bağlantısı (3D, 3DGate URL)"
      />
    </b-form-group>
  </b-card>
</template>
<script>
import { BCard, BFormGroup, BFormInput } from 'bootstrap-vue'

export default {
  name: 'Posnet',
  components: {
    BCard,
    BFormGroup,
    BFormInput,
  },
  computed: {
    posConfig() {
      return this.$store.getters['posConfigs/getPos_config']
    },
  },
}
</script>
<style scoped></style>
